import { useContext } from "react";
import { useMultipass } from "../../hooks/useMultipass";
import { useGameMasterBackend } from "../../hooks/useGameMasterBackend";
import { Web3ProviderContext } from "../../providers/Web3Provider/context";
import { Web3ProviderInterface } from "../../types";
import LoadingOverlay from "../LoadingOverlay";
import { Button, Flex, Title, Text, TextInput } from "@mantine/core";
import { useForm } from '@mantine/form';

export const RequireMultipass = ({ children }: { children: any }) => {
    const web3ProviderContext: Web3ProviderInterface = useContext(Web3ProviderContext);
    const multipassService = useMultipass(web3ProviderContext);
    const gameMasterService = useGameMasterBackend({});

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
          username: '',
        },
    
        validate: {
          username: (value) => value.length > 2 && value.length < 16 ? null : 'Enter a username between 2 and 16 characters.',
        },
    });
    
    if (multipassService.userName.isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    const handleSignUp = (values: { username: string }) => {
        if (!gameMasterService.signUp.isLoading && !multipassService.signUp.isLoading) {
            gameMasterService.signUp.mutate(
                { username: values.username },
                {
                    onSuccess: (r) => {
                        multipassService.signUp.mutate({
                            message: {
                                wallet: web3ProviderContext.account,
                                ...r.data.message,
                            },
                            registrarSignature: r.data.signature,
                        });
                    },
                }
            );
        }
    };

    if (multipassService.userName.data?.name === "")
        return (
            <Flex direction="column" justify="center" w="100%" gap="1rem">
                <Title ta="left" mt='1rem' fz="3rem">
                    New in {' '}
                    <Text inherit variant="gradient" component="span" gradient={{  from: 'cyan', to: 'teal' }}>
                        the hood?
                    </Text>
                </Title>
                <Text>
                    Hey fellow, haven&apos;t seen your account here before.
                </Text>
                <Text>
                    What shall be your name?
                </Text>
                <form onSubmit={form.onSubmit((values) => handleSignUp(values))}>
                <Flex direction="column" justify="center" w="100%" gap="1rem">
                    <TextInput
                        withAsterisk
                        label="Username"
                        placeholder="My name is.."
                        description="Enter a username between 2 and 16 characters."
                        key={form.key('username')}
                        size="md"
                        {...form.getInputProps('username')}
                    />
                    <Button
                        mt={4}
                        mx={0}
                        w="100%"
                        type="submit"
                        loading={gameMasterService.signUp.isLoading || multipassService.signUp.isLoading}
                    >
                        Submit
                    </Button>
                    </Flex>
                </form>
            </Flex>
        );

    return children;
};

export default RequireMultipass;