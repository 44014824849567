import { React } from "react";
import Navbar from "../components/navbar/Navbar";
import { TelegramProvider } from "../providers/TelegramProvider";
import { TelegramLoader } from "../components/loaders/TelegramLoader";
import { Flex, useMantineTheme } from "@mantine/core";

const LayoutWrapper = ({
    children,
    ...props
}: {
    children: React.ReactNode;
}) => {
    const theme = useMantineTheme();

    return (
        <TelegramProvider>
            <TelegramLoader>
                <Flex align="center"
                    justify="center">    
                    <Flex
                        mih="100vh"
                        maw={theme.breakpoints.xs}
                        direction="column"
                        style={{ flexGrow: 1, overwlofX: "hidden" }}
                        align="left"
                        justify="top"
                        bg="gray.9"
                        px="1rem"
                    >
                        <Navbar/>
                        {children}
                    </Flex>
                </Flex>
            </TelegramLoader>
        </TelegramProvider>
    );
};

export const getDefaultLayout = (page: React.ReactNode) => (
    <LayoutWrapper>{page}</LayoutWrapper>
);
export default LayoutWrapper;