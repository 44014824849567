import { usePrivy } from '@privy-io/react-auth';
import { Image } from '@mantine/core';
import LoginName from './LoginName';
import Logout from './Logout';
import { useContext, React } from "react";
import { Web3ProviderContext } from "../../providers/Web3Provider/context";
import { Web3ProviderInterface } from '../../types/web3';
import { useTelegram } from '../../providers/TelegramProvider';
import LoadingOverlay from '../LoadingOverlay';
import { Flex, Text } from '@mantine/core';

const Navbar = () => {
    const { ready, authenticated } = usePrivy();
    const web3ProviderContext: Web3ProviderInterface = useContext(Web3ProviderContext);
    const { telegramWebApp } = useTelegram();
    const showLogoutButton = telegramWebApp === undefined;
    
    if (web3ProviderContext?.isConnected) {
        return (
            <Flex direction="row" justify="space-between" gap="4" py="8">
                <Flex align="center">
                    <Image
                        display="inline" h="24px" mr="0.5rem"
                        src="/images/eth-diamond-rainbow.png" alt=''
                    ></Image>
                    <Text><LoginName web3ProviderContext={web3ProviderContext}></LoginName></Text>
                </Flex>
                { showLogoutButton && (<Logout web3ProviderContext={web3ProviderContext} />) }
            </Flex>
        )
    }  else if(ready && authenticated ){
        return  <LoadingOverlay />;
    } else {
        return '';
    }
};

export default Navbar;