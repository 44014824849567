import { useTelegram } from '../../providers/TelegramProvider';
import LoadingOverlay from "../LoadingOverlay";
export const TelegramLoader = ({ children }: { children: any }) => {
    const { loaded } = useTelegram();
    
    if (!loaded) { 
        return <LoadingOverlay />
    }
    else {
        return children;
    }
};
