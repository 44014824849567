import axios, { AxiosRequestConfig } from "axios";

export const http = async (config: AxiosRequestConfig<any>, withoutAuth = false) => {
    const token = sessionStorage.getItem("EEAToken");
    const authorization = !withoutAuth && token ? { Authorization: `EEA ${token}` } : {};
    const defaultHeaders = config.headers ?? {};
    const options = {
        ...config,
        headers: {
            ...defaultHeaders,
            ...authorization,
        },
    };

    return axios(options);
};

export const queryPublic = (uri: string) => {
    return axios({ method: "GET", url: uri });
};

export { axios };
