import { getDefaultLayout } from "../layouts";
import requirePrivyAuthorization from '../components/require/RequirePrivyAuthorization'; 
import { Flex, Image, Title, Text } from "@mantine/core";

const Home = () => {
    return (
        <Flex justify="center" align="center">
            <Flex direction="column" align="center" justify="center" gap="1rem">
                <Title ta="center" mt='1rem' fz="3rem">
                    Welcome to{' '}
                    <Text inherit variant="gradient" component="span" gradient={{  from: 'cyan', to: 'teal' }}>
                        Rankify
                    </Text>
                </Title>
                <Image src="logo.png" alt="Logo" maw="320px" />
            </Flex>
        </Flex>
    );
};
Home.getLayout = getDefaultLayout;
export default requirePrivyAuthorization(Home);