import { AxiosResponse } from "axios";
import { http } from "../utils";
import { GM_API_SERVER } from "../config";

const API = GM_API_SERVER;

export const getPlayerSalt = async ({
    turn,
    gameId,
}: {
    turn: string;
    gameId: string;
}): Promise<AxiosResponse<string>> => {
    return http({
        method: "GET",
        url: `${API}/player/salt/`,
        params: { gameId, turn },
    });
};

export const getYoutubeData = async (links) => {
    return http({
        method: "POST",
        url: `${API}/links/youtube/`,
        data: { urls: links },
    }) as Promise<AxiosResponse<any[]>>;
};

export const relayProposal =
    ({ turn, gameId }: { turn: string; gameId: string }) =>
    async ({ proposal }: { proposal: string }) => {
        return http({
            method: "POST",
            url: `${API}/player/proposal/${gameId}/${turn}`,
            data: { proposal },
        });
    };
