import { useMultipass } from "../../hooks/useMultipass";
import { Web3ProviderInterface } from '../../types/web3';

const LoginName = ({
    web3ProviderContext,
}: {
    web3ProviderContext: Web3ProviderInterface;
}) => {
    const multipassService = useMultipass(web3ProviderContext);
    const displayNameFromAccountKey = (): string => {
        return (
            web3ProviderContext.account?.slice(0, 6) + "..." + web3ProviderContext.account?.slice(-3)
        );
    };

    const LoginName = (): string =>
        multipassService.userName.data && multipassService.userName.data?.name !== ""
            ? multipassService.userName.data.name
            : displayNameFromAccountKey();

    return <LoginName />;
};  

export default LoginName;