import { ethers } from "ethers";
import { useNotifiaction } from "./useNotifiaction";

export const useWrapContractCall = () => {
    const notification = useNotifiaction();

    const wrapContractCall = async <T>(
        contractCall: () => Promise<T>,
        contract: ethers.Contract,
        showToast = false,
    ): Promise<T | null> => {
        try {
            return await contractCall();
        } catch (e: any) {
            if (e.error?.data || e.error?.error?.data) {
                const errorData = e.error?.data ?? e.error?.error.data;
                const decodedError = contract.interface.parseError(errorData.data ?? errorData);
                console.log(`Transaction failed with error: ${decodedError?.name}`);
                console.log(`Failed transaction args: ${decodedError?.args}`);
                if (showToast) {
                    notification(decodedError?.name ?? "Transaction failed", "error");
                }
            } else {
                console.error(`Error:`, e);
                if (showToast) {
                    notification("Transaction failed", "error");
                }
            }
            throw e;
        }
    };

    return { wrapContractCall };
};