import { useContext } from "react";
import { Web3ProviderInterface } from "../../types";
import { Web3ProviderContext } from "../../providers/Web3Provider/context";
import { WALLET_STATES } from "../../types";
import { LoadingOverlay } from "@mantine/core";

export const Web3ProviderLoader = ({ children }: { children: any }) => {
    const web3ProviderContext: Web3ProviderInterface = useContext(Web3ProviderContext);

    if (web3ProviderContext.connectionState !== WALLET_STATES.CONNECTED) { 
        return <LoadingOverlay />
    }
    else {
        return children;
    }
};
