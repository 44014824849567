import Script from "next/script";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { ITelegramWebApp, ITelegramContext } from "../../types";

export const TelegramContext = createContext<ITelegramContext>({});

export const TelegramProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [telegramWebApp, setWebApp] = useState<ITelegramWebApp | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      const user = app.initDataUnsafe.user;
      if (user) {
        setWebApp(app);
        app.ready();
        app.expand();
      }
      setLoaded(true);
    }
  }, []);

  const contextValue = useMemo(() => {
    return telegramWebApp
      ? {
          telegramWebApp: telegramWebApp,
          unsafeData: telegramWebApp.initDataUnsafe,
          user: telegramWebApp.initDataUnsafe.user,
          loaded: loaded,
        }
      : { loaded: loaded };
  }, [loaded]);

  return (
    <TelegramContext.Provider value={contextValue}>
         {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => useContext(TelegramContext);