import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePrivy } from '@privy-io/react-auth';
import { RequireMultipass } from './RequireMultipass';
import { Web3ProviderLoader } from '../../components/loaders/Web3ProviderLoader';

const RequirePrivyAuthorization = (WrappedComponent) => {
  const RequireAuth = (props) => {
    const { ready, authenticated } = usePrivy();
    const router = useRouter();

    useEffect(() => {
      if (ready && !authenticated) {
        router.push('/login');
      }
    }, [ready, authenticated, router]);

    if (!ready || !authenticated) {
      return null;
    }

    return (
      <Web3ProviderLoader>
        <RequireMultipass>
          <WrappedComponent {...props} />
        </RequireMultipass>
      </Web3ProviderLoader>
    );
  };

  return RequireAuth;
};

export default RequirePrivyAuthorization;