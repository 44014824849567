import { relayProposal } from "../services/gameMasterService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryCacheProps } from "./hookCommon";
import useNotifiaction from "./useNotifiaction";
import { http } from "../utils";
import { BigNumberish } from "ethers";
//TODO: import baseQueryKey from useRankifyGame when it will be implemented
//import { baseQueryKey } from "./useRankifyGame";
import { GM_API_SERVER } from "../config";

const baseQueryKey = ["game", "contract", "instance"];
const API = GM_API_SERVER;

export const useGameMasterBackend = ({
    turn,
    gameId,
    skipProposal = false,
}: {
    turn?: string;
    gameId?: string;
    skipProposal?: boolean;
}) => {
    const notifiaction = useNotifiaction();
    const qClient = useQueryClient();

    const prevTurnProposalId = useQuery(
        ["GMBackend", "myProposalId", gameId, Number(turn) - 1],
        async () =>
            http({
                method: "GET",
                url: `${API}/player/proposal/id/${gameId}/${Number(turn) - 1}`,
            }).then((r) => r.data),
        {
            ...queryCacheProps,
            enabled:
                !!gameId &&
                !!turn &&
                !isNaN(Number(turn) - 1) &&
                Number(turn) - 1 != 0 &&
                Number(gameId) != 0,
        }
    );

    const myProposalId = useQuery(
        ["GMBackend", "myProposalId", gameId, turn],
        async () =>
            http({
                method: "GET",
                url: `${API}/player/proposal/id/${gameId}/${turn}`,
            }).then((r) => r.data),
        {
            ...queryCacheProps,
            enabled:
                !!gameId &&
                !!turn &&
                !isNaN(Number(turn)) &&
                !skipProposal &&
                Number(turn) != 0 &&
                Number(gameId) != 0,
        }
    );

    const submitVoteMutation = useMutation(
        async ({ vote }: { vote: BigNumberish[] }) => {
            if (!gameId) throw new Error("No GameId");
            if (!turn) throw new Error("turn number");
            return http({
                method: "POST",
                url: `${API}/player/vote/${gameId}/${turn}`,
                data: { vote },
            });
        },
        {
            onSuccess: () => {
                qClient.refetchQueries(baseQueryKey);
            },
            onError: (e: any) => {
                notifiaction(e?.response?.data, "error");
            },
        }
    );

    const submitProposalMutation = useMutation(
        async ({ proposal }: { proposal: string }) => {
            if (!gameId) throw new Error("No GameId");
            if (!turn) throw new Error("turn number");
            return relayProposal({ turn, gameId })({ proposal });
        },
        {
            onError: (e: any) => {
                notifiaction(e?.response?.data, "error");
            },
        }
    );
    
    const endTurn = useMutation(
        async ({ gameId }: { gameId: string }) => {
            if (!gameId) throw new Error("No GameId");
            return http({
                method: "POST",
                url: `${API}/endturn/${gameId}`,
            });
        },
        {
            onSuccess: () => {
                qClient.refetchQueries(baseQueryKey);
            },
        }
    );

    const signUp = useMutation(
        async ({ username }: { username: string }) => {
            return http({
                method: "POST",
                url: `${API}/registrar/sign`,
                data: { username },
            });
        },
        {
            onSuccess: () => {
                qClient.refetchQueries(baseQueryKey);
            },
        }
    );

    return {
        submitProposalMutation,
        submitVoteMutation,
        endTurn,
        myProposalId,
        prevTurnProposalId,
        signUp,
    };
};

export default useGameMasterBackend;
