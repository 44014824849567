import { Multipass, SupportedChains, getContract, LibMultipass } from "rankify-js";
import { WALLET_STATES, Web3ProviderInterface } from "../types";
import { useMutation, useQuery } from "react-query";
import { queryCacheProps } from "./hookCommon";
import { ethers } from "ethers";
import { DOMAIN_NAME } from "../config";
import { useWrapContractCall } from "./useWrapUpContractCall";
import useNotifiaction from "./useNotifiaction";

export const useMultipass = (web3ProviderContext: Web3ProviderInterface) => {
    if (!web3ProviderContext.currentChain) throw new Error("No chain selected");
    const chainDeploymentName = web3ProviderContext.currentChain.name  as SupportedChains;
    if (!chainDeploymentName) throw new Error("Current set chain has no deployment name");
    const usernameQueryKey = ["multipass", "username", web3ProviderContext.account];
    const { wrapContractCall } = useWrapContractCall();
    const notification = useNotifiaction();

    const emptyUserQuery: LibMultipass.NameQueryStruct = {
        name: ethers.utils.formatBytes32String(""),
        id: ethers.utils.formatBytes32String(""),
        domainName: ethers.utils.formatBytes32String(""),
        wallet: ethers.constants.AddressZero,
        targetDomain: ethers.utils.formatBytes32String(""),
    };

    const userName = useQuery<{name: string, id: string} | null>(
        usernameQueryKey,
        () => {
            if (!web3ProviderContext.signer) throw new Error("Signer is not defined");
            const multipassContract = getContract(chainDeploymentName, "Multipass", web3ProviderContext.signer) as any;

            const multipass = new Multipass({
                chainName: chainDeploymentName,
            });

            return wrapContractCall(
                () => multipassContract
                .resolveRecord(
                    multipass.formQueryByAddress({
                        address: web3ProviderContext.account ?? "",
                        domainName: DOMAIN_NAME,
                    })
                )
               .then(([success, record]) => {
                    return {
                        name: ethers.utils.parseBytes32String(record.name),
                        id: record.id,
                    };
                }),
                multipassContract,
                true
            );
        },
        {
            ...queryCacheProps,
            refetchInterval: false,
            enabled:
               web3ProviderContext.connectionState === WALLET_STATES.CONNECTED,
            onSettled: (e) => {},
        }
    );

    const signUp = useMutation(
        ({
            message,
            registrarSignature,
        }: {
            message: LibMultipass.RecordStruct;
            registrarSignature: string;
        }) => {
            if (!web3ProviderContext.signer) throw new Error("Signer is not defined");
            const multipassContract = getContract(chainDeploymentName, "Multipass", web3ProviderContext.signer) as any;

            return wrapContractCall(
                () => multipassContract.register(
                    message,
                    registrarSignature,
                    emptyUserQuery,
                    ethers.constants.HashZero,
                ),
                multipassContract,
                true
            );
        },
        {
            onSuccess: (e) => {
                notification("Username registered!", "success");
                userName.refetch();
            },
        }
    );
    return { userName, signUp };
};
