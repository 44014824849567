export const queryCacheProps = {
    enabled: true,
    retryDelay: (attempt: number) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    refetchOnWindowFocus: false,
    staleTime: 50000,
    refetchOnMount: true,
    refetchInterval: 1000 * 60,
    refetchOnReconnect: true,
    retry: (failureCount: number, error: any) => {
        const status = error?.response?.status;
        return status === 404 || status === 403 ? false : true;
    },
};
